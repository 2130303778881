import _ from "lodash";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import { Form, Icon, Message } from "semantic-ui-react";

import { login } from "../../fetcher";
import { UserContext } from "../../context";
import { FORGET_FORM } from "../../helpers/constant";
import { SEKOLAH_KODE } from "../../main.config";

const ForgetPassword = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: absolute;

  > span {
    font-weight: 400;
    font-size: 0.9em;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const LoginSection = ({ setForm }) => {
  const [isLoading, toggleLoading] = useState(false);
  const [lookPass, setLookPass] = useState(false);
  const [formValue, setFormValue] = useState({
    sekolah_kode: SEKOLAH_KODE,
    username: "",
    password: "",
  });
  const [formStatus, setFormStatus] = useState({});

  const session = useContext(UserContext);

  useEffect(() => {
    document.title = "Sistem Manajemen Sekolah";
  });

  const handleSubmit = async () => {
    toggleLoading(true);
    const response = await login(formValue);
    if (response.status_code === 200) {
      await session.checkUser();
      return <Redirect to="/" />;
    } else {
      const formStatus = { status: "error", props: {} };
      switch (response.message) {
        case "Username and Password combination is invalid": {
          formStatus.props = {
            error: true,
            size: "tiny",
            header: "Login Gagal",
            content:
              "Kombinasi username dan password yang Anda masukan tidak sesuai",
          };
          break;
        }
        default:
      }
      toggleLoading(false);
      return setFormStatus(formStatus);
    }
  };
  const handleChange = (e, data) => {
    const { name, value } = data;
    setFormStatus({});
    return setFormValue(_.assign({}, formValue, { [name]: value }));
  };

  const { username, password } = formValue;
  const is_error = _.isEqual(formStatus.status, "error");
  return (
    <Form className="theme" error={is_error}>
      <Form.Field required>
        <label>Username</label>
        <Form.Input
          disabled={isLoading}
          name="username"
          value={username || ""}
          onChange={handleChange}
          placeholder="Masukkan username unik Anda"
        />
      </Form.Field>
      <Form.Field required>
        <ForgetPassword style={{ fontWeight: "400" }}>
          <span className="unselectable" onClick={() => setForm(FORGET_FORM)}>
            Lupa Password ?
          </span>
        </ForgetPassword>
        <label>Password</label>
        <Form.Input
          disabled={isLoading}
          name="password"
          value={password || ""}
          onChange={handleChange}
          icon={
            <Icon
              name={lookPass ? "eye" : "eye slash"}
              link
              onClick={() => setLookPass(!lookPass)}
            />
          }
          type={lookPass ? "text" : "password"}
          placeholder="Masukkan password / kata sandi akun Anda"
        />
      </Form.Field>
      {!_.isEmpty(formStatus) && <Message {...formStatus.props} />}
      {/* {isShowForget && <Header as="h5">Hubungi <Link href="https://wa.me/6281228800048" target="_blank">081228800048</Link> via WhatsApp / SMS</Header>} */}
      <Form.Button
        color="theme"
        type="submit"
        floated="right"
        disabled={isLoading}
        loading={isLoading}
        onClick={handleSubmit}
      >
        Masuk <Icon name="arrow right" />
      </Form.Button>
    </Form>
  );
};

export default LoginSection;
