import Loadable from "../../../components/Loadable";

const DaftarPrestasiMurid = Loadable(() => import("./DaftarPrestasiMurid"));
const DetilPrestasiMurid = Loadable(() => import("./DetilPrestasiMurid"));

const DaftarPrestasiLomba = Loadable(() => import("./DaftarPrestasiLomba"));
const DetilPrestasiLomba = Loadable(() => import("./DetilPrestasiLomba"));

const DaftarPrestasiAkademik = Loadable(
  () => import("./DaftarPrestasiAkademik"),
);
const DetilPrestasiAkademik = Loadable(() => import("./DetilPrestasiAkademik"));

const DaftarPrestasiOrganisasi = Loadable(
  () => import("./DaftarPrestasiOrganisasi"),
);
const DetilPrestasiOrganisasi = Loadable(
  () => import("./DetilPrestasiOrganisasi"),
);

const DaftarPrestasiEkstrakurikuler = Loadable(
  () => import("./DaftarPrestasiEkstrakurikuler"),
);
const DetilPrestasiEkstrakurikuler = Loadable(
  () => import("./DetilPrestasiEkstrakurikuler"),
);

const HafalanSurah = Loadable(() => import("./HafalanSurah/DaftarKelas"));
const DetilHafalan = Loadable(() => import("./HafalanSurah/DetilHafalan"));

const PrestasiLainnya = Loadable(() => import("./DaftarPrestasiLainnya"));
const DetilPrestasiLainnya = Loadable(() => import("./DetilPrestasiLainnya"));
const TambahPrestasiLainnya = Loadable(() => import("./TambahPrestasiLainnya"));

const Prestasi = {
  DaftarPrestasiMurid,
  DetilPrestasiMurid,
  DaftarPrestasiLomba,
  DetilPrestasiLomba,
  DaftarPrestasiOrganisasi,
  DetilPrestasiOrganisasi,
  DaftarPrestasiEkstrakurikuler,
  DetilPrestasiEkstrakurikuler,
  DaftarPrestasiAkademik,
  DetilPrestasiAkademik,
  HafalanSurah,
  DetilHafalan,
  PrestasiLainnya,
  DetilPrestasiLainnya,
  TambahPrestasiLainnya,
};

export default Prestasi;
