import _ from "lodash";
import L from "react-loadable";
import React from "react";

const Loading = () => <React.Fragment />;

const defaultConfig = { timeout: 10000, loading: Loading };

const Loadable = (loader) => L(_.assign({}, defaultConfig, { loader }));

export default Loadable;
