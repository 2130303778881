import React, { memo, useContext } from "react";
import styled from "styled-components";
import { Input } from "semantic-ui-react";
import { UserContext } from "../../../../context";

const MoleculeSearch = (props) => {
  const { state } = useContext(UserContext);

  return (
    <Style nav={state.navigation.active ? true : state.navigation.hover}>
      <Input
        fluid
        icon="search"
        iconPosition="left"
        placeholder="Cari menu"
        value={props.search}
        onChange={props.handleSearch}
      />
    </Style>
  );
};

const Style = styled.div`
  margin: 16px 10px;
  display: ${(props) => (props.nav ? "block" : "none")};

  .ui.fluid.input > input {
    padding-left: 56px !important;
  }

  .ui.icon.input > i.icon:not(.link) {
    margin-left: 6px;
  }

  @media (max-width: 480px) {
    display: block;
  }
`;

export default memo(MoleculeSearch);
