import Loadable from "../../../components/Loadable";

const DaftarBiodataMurid = Loadable(() => import("./DaftarBiodataMurid"));
const DetilBiodataMurid = Loadable(() => import("./DetilBiodataMurid"));
const KelengkapanMurid = Loadable(() => import("./KelengkapanMurid"));
const IkhtisarMurid = Loadable(() => import("./IkhtisarMurid"));
const DetilIkhtisarMurid = Loadable(() => import("./DetilIkhtisarMurid"));
const CetakMutasi = Loadable(() => import("./CetakMutasi"));
const DetilCetakMutasi = Loadable(() => import("./DetilCetakMutasi"));
const PenguncianAksesMurid = Loadable(
  () => import("./PenguncianAksesMurid/index"),
);

const NilaiIndex = {
  DetilIkhtisarMurid,
  IkhtisarMurid,
  KelengkapanMurid,
  DetilBiodataMurid,
  DaftarBiodataMurid,
  CetakMutasi,
  DetilCetakMutasi,
  PenguncianAksesMurid,
};

export default NilaiIndex;
