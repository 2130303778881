import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import MoleculeSearch from "../molecules/MoleculeSearch";
import MoleculeSekolah from "../molecules/MoleculeSekolah";
import MoleculeToggleNav from "../molecules/MoleculeToggleNav";
import MoleculeMenu from "../molecules/MoleculeMenu";
import MoleculeLogoImpuls from "../molecules/MoleculeLogoImpuls";
import { UserContext } from "../../../../context";

const OrganismDashboardLeft = (props) => {
  const { state, action, user, model } = useContext(UserContext);
  const [search, setSearch] = useState("");

  const handleSearch = useCallback((e, { value }) => setSearch(value), []);
  const resetSearch = useCallback(() => setSearch(""), []);

  const pembayaran = model === "ppdb" && user?.paid === false;
  return (
    <Style
      pembayaran={pembayaran}
      nav={state.navigation.active ? true : state.navigation.hover}
    >
      <div className="dashboard-left-top">
        <div className="dashboard-header">
          <MoleculeSekolah />
          <MoleculeToggleNav />
        </div>
        <div
          className="dashboard-left-desktop"
          onMouseEnter={() => action.hoverNav(true)}
          onMouseLeave={() => action.hoverNav(false)}
        >
          <MoleculeSearch search={search} handleSearch={handleSearch} />
          <div className="dashboard-menu-container">
            <MoleculeMenu
              history={props.history}
              search={search}
              resetSearch={resetSearch}
            />
          </div>
        </div>
        <div className="dashboard-left-mobile">
          <MoleculeSearch search={search} handleSearch={handleSearch} />
          <div className="dashboard-menu-container">
            <MoleculeMenu
              history={props.history}
              search={search}
              resetSearch={resetSearch}
            />
          </div>
        </div>
      </div>
      <MoleculeLogoImpuls />
    </Style>
  );
};

const Style = styled.div`
  background-color: #f4f6f8;
  height: calc(100vh - ${(p) => (p.pembayaran ? "50px" : "0px")});
  overflow: hidden;
  display: grid;
  box-shadow: rgb(121 121 122 / 20%) 1px 0px 4px 0px;
  min-width: ${(props) => (props.nav ? "280px" : "70px")};
  max-width: ${(props) => (props.nav ? "350px" : "100px")};
  z-index: 99;

  .dashboard-left-top {
    align-self: start;
  }

  .dashboard-left-mobile {
    display: none;
  }

  .dashboard-menu-container {
    overflow: hidden;
    margin: 0 10px;
    border-radius: 4px;
  }

  @media only screen and (max-width: 768px) {
    height: 100%;
    min-width: ${(props) => (props.nav ? "280px" : "0px")};
    display: ${(props) => !props.nav && "none"};

    .dashboard-header {
      display: none;
    }

    .dashboard-left-desktop {
      display: none;
    }

    .dashboard-left-mobile {
      display: block;
      flex: 1;
    }
  }
`;

export default OrganismDashboardLeft;
