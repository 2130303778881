import React, { memo, useCallback, useContext, useState } from "react";
import styled from "styled-components";
import MoleculeDropdown from "./MoleculeDropdown";
import { UserContext } from "../../../../context";
import _ from "lodash";

const data = require("../../test/APICheck.json");

const MoleculeMenu = (props) => {
  const session = useContext(UserContext);
  const [selected, setSelected] = useState("");

  const pembayaran = session.model === "ppdb" && session.user?.paid === false;

  const acl = []
    .concat(...data.result.roles.map((r) => r.acl))
    .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  const menu_list = _.chain(session)
    .get("list_menu", [])
    .map((o) => {
      const parent = _.get(o, "parent", {});
      const map_child = _.get(o, "child", []).map((oo) =>
        _.set(oo, "parent", parent.id),
      );
      return [parent, ...map_child];
    })
    .flatten()
    .value();
  const menu_filter = menu_list.filter((m) => acl.filter((a) => a.id === m.id));
  const menu_nested = () => {
    const nested = [];

    menu_filter.forEach((m) => {
      if (m.parent) {
        const index = nested.findIndex((n) => n.id === m.parent);
        nested[index].child.push(m);
      } else {
        nested.push({ ...m, child: [] });
      }
    });
    return nested;
  };

  const menus = menu_nested();

  const handleSelected = useCallback(
    (id) => {
      selected === id ? setSelected("") : setSelected(id);
    },
    [selected],
  );

  const search = props.search.toLowerCase();

  return (
    <Style pembayaran={pembayaran}>
      <div className="dashboard-menu">
        {menus.map((item) => (
          <MoleculeDropdown
            {...item}
            selected={selected}
            handleSelected={handleSelected}
            search={search}
            history={props.history}
            resetSearch={props.resetSearch}
            key={item.id}
          />
        ))}
      </div>
    </Style>
  );
};

const Style = styled.div`
  height: calc(100vh - 248px - ${(p) => (p.pembayaran ? "50px" : "0px")});
  align-content: start;
  overflow: auto;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0px;
  }

  .dashboard-menu {
    display: grid;
    gap: 0.5em;
  }

  @media (max-width: 480px) {
    height: calc(100vh - 196px);
  }
`;

export default memo(MoleculeMenu);
