import Loadable from "../../../components/Loadable";

const IkhtisarUmum = Loadable(() => import("./IkhtisarUmum"));
const IkhtisarAkademik = Loadable(() => import("./IkhtisarAkademik"));
const IkhtisarKesiswaan = Loadable(() => import("./IkhtisarKesiswaan"));
const IkhtisarTataTertib = Loadable(() => import("./IkhtisarTataTertib"));
const IkhtisarKeuangan = Loadable(() => import("./IkhtisarKeuangan"));
const NilaiRapor = Loadable(() => import("./NilaiRapor"));

export default {
  NilaiRapor,
  IkhtisarKeuangan,
  IkhtisarTataTertib,
  IkhtisarKesiswaan,
  IkhtisarAkademik,
  IkhtisarUmum,
};
