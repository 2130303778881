import React from "react";
import { Header, Icon } from "semantic-ui-react";

export const Error423 = (props) => {
  const size = props.size || "medium";

  let textSize = "1.2em";
  let headerSize = "h2";
  let iconSize = "big";

  if (size === "small") {
    textSize = "1em";
    headerSize = "h5";
    iconSize = "large";
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "10%",
        height: "100%",
      }}
    >
      <Icon
        name="lock"
        color="blue"
        style={{ padding: 0, margin: 0 }}
        size={iconSize}
      />
      <Header
        color="blue"
        style={{ marginBottom: ".8em", marginTop: ".4em" }}
        as={headerSize}
      >
        Konten Terkunci
      </Header>
      <p style={{ margin: 0, fontSize: textSize, fontWeight: "500" }}>
        Anda belum diizinkan oleh pihak sekolah untuk melihat data ini.
      </p>
      <p style={{ margin: 0, fontSize: textSize, fontWeight: "500" }}>
        Hubungi Wali Kelas atau Tata Usaha untuk keterangan lebih lanjut.
      </p>
    </div>
  );
};
