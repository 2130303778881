import Loadable from "../components/Loadable";

import Ikhtisar from "../views/Murid/Ikhtisar/Index";
import Peminatan from "../views/Murid/Peminatan/Index";
import Pengumuman from "../views/Karyawan/Pengumuman/Index";
import DaftarUlang from "../views/Murid/DaftarUlang/Index";
import KuesionerView from "../views/Murid/KuesionerView/Index";
import Ekstrakurikuler from "../views/Karyawan/Ekstrakurikuler/Index";
import DaftarRaporMurid from "../views/Karyawan/Rapor/DaftarRaporMurid";

const Profil = Loadable(() => import("../views/Murid/Profil/Profil"));

const base_url = "";
export default [
  {
    path: [base_url, "pengumuman"].join("/"),
    component: Pengumuman.DaftarPengumumanView,
  },
  {
    path: [base_url, "pengumuman", ":id"].join("/"),
    component: Pengumuman.DetilPengumumanView,
  },

  { path: [base_url, "rapor"].join("/"), component: DaftarRaporMurid },

  {
    path: [base_url, "daftar-ulang"].join("/"),
    component: DaftarUlang.DaftarUlang,
  },
  { path: [base_url, "peminatan"].join("/"), component: Peminatan.Peminatan },
  {
    path: [base_url, "ikhtisar", "umum"].join("/"),
    component: Ikhtisar.IkhtisarUmum,
  },
  {
    path: [base_url, "ikhtisar", "nilai-rapor"].join("/"),
    component: Ikhtisar.NilaiRapor,
  },
  {
    path: [base_url, "ikhtisar", "akademik"].join("/"),
    component: Ikhtisar.IkhtisarAkademik,
  },
  {
    path: [base_url, "ikhtisar", "kesiswaan"].join("/"),
    component: Ikhtisar.IkhtisarKesiswaan,
  },
  {
    path: [base_url, "ikhtisar", "tata-tertib"].join("/"),
    component: Ikhtisar.IkhtisarTataTertib,
  },
  {
    path: [base_url, "ikhtisar", "keuangan"].join("/"),
    component: Ikhtisar.IkhtisarKeuangan,
  },

  // { path: [base_url, 'daftar-ulang'].join('/'), component: DaftarUlang },
  {
    path: [base_url, "kuesioner"].join("/"),
    component: KuesionerView.DaftarKuesioner,
  },
  {
    path: [base_url, "kuesioner", ":_id"].join("/"),
    component: KuesionerView.DetilKuesioner,
  },

  {
    path: [base_url, "ekstrakurikuler", "daftar-nilai"].join("/"),
    component: Ekstrakurikuler.NilaiEkskul,
  },
  {
    path: [base_url, "ekstrakurikuler", "daftar-nilai", ":_id"].join("/"),
    component: Ekstrakurikuler.DetilNilaiEkskul,
  },
  {
    path: [base_url, "ekstrakurikuler", "daftar-ekskul"].join("/"),
    component: Ekstrakurikuler.DaftarEkskul,
  },
  {
    path: [base_url, "ekstrakurikuler", "daftar-ekskul", ":_id"].join("/"),
    component: Ekstrakurikuler.DetilDaftarEkskul,
  },

  { path: [base_url, "profil"].join("/"), component: Profil },
];
