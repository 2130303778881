import Loadable from "../../../components/Loadable";

const AkunKaryawan = Loadable(
  () => import("../Sistem/AkunKaryawan/AkunKaryawan"),
);
const TambahKaryawan = Loadable(
  () => import("../Sistem/AkunKaryawan/TambahKaryawan"),
);
const DetilProfilKaryawan = Loadable(
  () => import("../Sistem/AkunKaryawan/DetilProfilKaryawan"),
);
const AkunMurid = Loadable(() => import("../Sistem/AkunMurid/AkunMurid"));
const TambahMurid = Loadable(() => import("../Sistem/AkunMurid/TambahMurid"));
const DetilProfilMurid = Loadable(
  () => import("../Sistem/AkunMurid/DetilProfilMurid"),
);
const IdentitasSekolah = Loadable(
  () => import("../Sistem/IdentitasSekolah/IdentitasSekolah"),
);
const KepalaSekolah = Loadable(
  () => import("../Sistem/KepalaSekolah/KepalaSekolah"),
);
const PenguncianAkses = Loadable(
  () => import("../Sistem/PenguncianAkses/PenguncianAkses"),
);

export default {
  AkunKaryawan,
  TambahKaryawan,
  DetilProfilKaryawan,
  AkunMurid,
  DetilProfilMurid,
  TambahMurid,
  PenguncianAkses,
  KepalaSekolah,
  IdentitasSekolah,
};
