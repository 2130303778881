import Loadable from "../components/Loadable";

const Profil = Loadable(() => import("../views/PPDB/Profil"));
const Pengisian = Loadable(() => import("../views/PPDB/Pengisian"));
const Pengumuman = Loadable(() => import("../views/PPDB/Pengumuman"));

const base_url = "/ppdb";
export default [
  { path: [base_url, "profil"].join("/"), component: Profil },
  { path: [base_url, "pengumuman"].join("/"), component: Pengumuman },
  { path: [base_url, "data"].join("/"), component: Pengisian },
];
