import _ from "lodash";
import styled from "styled-components";
import { Header, Button } from "semantic-ui-react";
import { Redirect, Route, Switch } from "react-router-dom";
import React, { useContext } from "react";

// import Sidebar from '../components/Sidebar';

import { UserContext } from "../context";

// import logo_impuls from '../assets/logo_impuls.png';
import logo_sekolah from "../assets/logo_sekolah.png";
import UnderMaintenance from "./Public/UnderMaintenance";

import OrganismDashboardLeft from "../collection/Sidebar/components/organisms/OrganismDashboardLeft";
import Pembayaran from "../collection/DetilPeserta/Pembayaran";

const Layout = styled.div`
  display: grid;
  position: absolute;
  width: 100%;
  height: 100%;
  grid-template-columns: auto 1fr;

  > .top-navigation {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    grid-template-rows: auto 1fr;
    > .top-navigation {
      background: #f4f6f8;
      grid-column: span 2;
      display: grid;
      grid-template-columns: 3em 1fr;
      padding: 0.6em 0.4em;
      align-items: center;

      > div:first-child {
        text-align: center;
      }
      > div:last-child {
        display: grid;
        grid-gap: 0 0.4em;
        align-items: center;
        grid-template-rows: auto auto;
        grid-template-columns: 3em 1fr;
        > div:first-child {
          text-align: center;
        }
      }
    }
    > div:last-child {
      padding: 0;
    }
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 1.5rem 1.5rem 0 1.5rem;
  overflow: hidden;
  overflow-y: auto;
`;

const ISMS = <p className="unselectable">Sistem Manajemen Sekolah</p>;

const TopNavigation = ({ visible, toggle, nama, isms }) => {
  return (
    <div className="top-navigation">
      <div>
        <Button
          size="big"
          basic
          color="theme"
          icon={visible ? "angle double left" : "angle double right"}
          className="sidebar-btn"
          onClick={toggle}
        />
      </div>
      <div>
        <div style={{ gridRow: "span 2" }}>
          <img src={logo_sekolah} width="85%" alt="SMA" />
        </div>
        <div>{nama}</div>
        <div>{isms}</div>
      </div>
    </div>
  );
};

const Dashboard = ({ location, history }) => {
  const session = useContext(UserContext);

  const nama_sekolah = _.get(session, "sekolah.nama");
  const perbaikan = _.get(session, "sekolah.perbaikan");

  // const [expand, setExpand] = useState(true);
  // const [visible, setVisible] = useState(true);

  const uri = _.get(location, "pathname", "");
  const { list_menu = [], list_route } = session;
  const default_to = _.get(list_menu, [0, "parent", "to"], "/");

  if (!session.is_login) {
    return <Redirect to="/login" />;
  }

  if (session.is_login && perbaikan) {
    return <UnderMaintenance nama={nama_sekolah} />;
  }
  if (session.is_login && _.isEqual(uri, "/")) {
    // console.log('#1 Redirect to Top Level Menu')
    return <Redirect to={default_to} />;
  }

  // console.log('list_route', list_route);
  return (
    <Layout>
      <div style={{ gridColumn: "span 2", backgroundColor: "red" }}>
        <Pembayaran />
      </div>
      <TopNavigation
        visible={
          session.state.navigation.active || session.state.navigation.hover
        }
        toggle={session.action.handleNav}
        nama={
          <Header as="h4" className="unselectable" color="theme">
            {nama_sekolah}
          </Header>
        }
        isms={ISMS}
      />
      <div>
        <OrganismDashboardLeft history={history} />
      </div>
      <Content>
        <Switch>
          {_.toArray(list_route).map((item, index) => (
            <Route exact key={index} {...item} />
          ))}
          <Route
            key="DEFAULT"
            path="/"
            component={() => {
              // console.log('#2 Redirect to Top Level Menu')
              return <Redirect to={default_to} />;
            }}
          />
        </Switch>
      </Content>
    </Layout>
  );
};

export default Dashboard;
