import _, { cloneDeep } from "lodash";
import { Loader } from "semantic-ui-react";
import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import PPDB from "./views/PPDB/Pendaftaran";
import Login from "./views/Login/Index";
import Dashboard from "./views/Dashboard";

import routes from "./routes";
import { UserContext } from "./context";
import { generateListMenu } from "./daftar_menu.js";
import { check } from "./fetcher";
import {
  BATAS_POIN_PELANGGARAN,
  DEFAULT_KKM,
  JENJANG,
  JENJANG_SEBELUMNYA,
  NAMA_SEKOLAH,
  OPTIONS,
} from "./helpers/constant";
import reducer from "./collection/Sidebar/context/reducer";
import { useReducer } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ModalKeluar } from "./collection/Sidebar/components/organisms/ModalKeluar.jsx";

const buildMenu = (list_menu, list_role) => {
  const acl = _.flatMap(list_role, (o) => o.acl).filter(
    (o) => !_.isEmpty(o.level),
  );

  let allowed = _.intersectionBy(list_menu, acl, "id");
  // let allowed = _.intersectionBy(list_menu, acl, 'id').filter(o => !_.isEmpty(o.parent));
  let list_parent = _.chain(allowed)
    .map((o) => _.first(o.id.split("#")))
    .uniq()
    .compact()
    .value();
  list_parent = list_menu.filter((o) => _.includes(list_parent, o.id));
  allowed = _.groupBy(allowed, "parent");

  const retval = _.chain(list_parent)
    .map((o) => {
      const child = _.get(allowed, o.id, []).map((o) => _.omit(o, "parent"));
      const item = {
        child,
        parent: _.assign(
          { to: _.chain(child).first().get("to", "").value() },
          o,
        ),
      };
      return item;
    })
    .value();

  return retval;
};
const buildRoute = (list_route, model) =>
  _.chain(list_route)
    .filter((o) => _.includes(o.list_model, model))
    .value();

const initialState = {
  navigation: {
    active: true,
    hover: false,
  },
  sekolah: {
    id: "sma-negeri-1-lorem-ipsum",
    nama: "DEFAULT NAMA SEKOLAH",
  },
};

const queryClient = new QueryClient({
  defaultOptions: { retry: 1 },
});

const App = () => {
  const [session, setSession] = useState({});
  const [isLoading, setLoading] = useState(true);

  const checkUser = useCallback(async () => {
    setLoading(true);
    const session = _.get(await check(), "result", {});
    const { roles, user } = session;
    const nama_sekolah = _.get(session, "sekolah.nama", "");
    const list_kelas_program = _.get(session, "list_kelas_program", []);
    const list_kelas_tingkat = _.get(session, "list_kelas_tingkat", []);
    const jenjang = _.get(session, "header.jenjang", "");
    const kkm = _.get(session, "header.kkm", "");
    const batas_poin = _.get(session, "header.batas_poin", "");
    const jenjang_sebelumnya = _.get(session, "header.jenjang_sebelumnya", "");
    const jenjang_sebelumnya_kecil = _.get(
      session,
      "header.jenjang_sebelumnya_kecil",
      "",
    );
    const list_organisasi = _.get(session, "header.list_organisasi", []);

    const list_acl = _.flatMap(roles, (o) => o.acl).filter(
      (o) => !_.isEmpty(o.level),
    );
    const map_acl = _.chain(list_acl)
      .groupBy("id")
      .mapValues((arr) =>
        _.assign({}, _.last(arr), {
          level: _.chain(arr)
            .reduce((sum, o) => sum + _.get(o, "level", ""), "")
            .split("")
            .uniq()
            .compact()
            .value()
            .join(""),
        }),
      )
      .value();
    _.set(session, "list_acl", list_acl);
    _.set(session, "map_acl", map_acl);

    if (session.is_login) {
      const { nama } = user;
      const all_menu = generateListMenu({
        nama,
        handleLogout: () => {
          const newSession = { ...cloneDeep(session) };
          _.set(newSession, "ui.modal_keluar", true);
          return setSession(newSession);
        },
      });
      const list_menu = buildMenu(all_menu, session.roles);
      const list_route = buildRoute(routes, session.model);

      // console.log("all_menu", all_menu);
      // console.log("list_menu", list_menu);
      // console.log("list_route", list_route);
      _.set(session, "list_route", list_route);
      _.set(session, "list_menu", list_menu);
      _.set(session, "list_warna", OPTIONS.OPTS_COLOR);
    }

    if (_.isEmpty(nama_sekolah)) {
      _.set(session, "sekolah.nama", NAMA_SEKOLAH);
    }
    if (_.isEmpty(list_kelas_program)) {
      const list_program = _.get(
        session,
        "header.list_program",
        OPTIONS.OPTS_KELAS_PROGRAM,
      );
      _.set(session, "list_kelas_program", list_program);
    }
    if (_.isEmpty(list_kelas_tingkat)) {
      const list_tingkat = _.get(
        session,
        "header.list_tingkat",
        OPTIONS.OPTS_KELAS_TINGKAT,
      );
      _.set(session, "list_kelas_tingkat", list_tingkat);
    }
    if (_.isEmpty(jenjang)) {
      _.set(session, "header.jenjang", JENJANG);
    }
    if (_.isEmpty(kkm) && !_.isNumber(kkm)) {
      _.set(session, "header.kkm", DEFAULT_KKM);
    }
    if (_.isEmpty(batas_poin) && !_.isNumber(batas_poin)) {
      _.set(session, "header.batas_poin", BATAS_POIN_PELANGGARAN);
    }
    if (_.isEmpty(jenjang_sebelumnya)) {
      _.set(session, "header.jenjang_sebelumnya", JENJANG_SEBELUMNYA);
    }
    if (_.isEmpty(jenjang_sebelumnya_kecil)) {
      _.set(
        session,
        "header.jenjang_sebelumnya_kecil",
        _.lowerCase(_.get(session, "header.jenjang_sebelumnya", "")),
      );
    }
    if (_.isEmpty(list_organisasi)) {
      _.set(session, "header.list_organisasi", OPTIONS.OPTS_NAMA_ORGANISASI);
    }

    /** Set Keluar Modal */
    _.set(session, "ui.modal_keluar", false);

    _.set(session, "setSession", setSession);
    setSession(session);
    return setLoading(false);
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);
  const handleNav = () => dispatch({ type: "HANDLE_NAV" });
  const hoverNav = (status) => dispatch({ type: "HOVER_NAV", payload: status });

  const action = { handleNav, hoverNav };

  useEffect(() => {
    // console.log('Get user data');
    checkUser();
  }, [checkUser]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserContext.Provider
        value={_.assign({ checkUser }, session, { action, state })}
      >
        <BrowserRouter>
          {isLoading ? (
            <Loader />
          ) : (
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/pendaftaran" component={PPDB} />
              <Route path="/" component={Dashboard} />
            </Switch>
          )}
        </BrowserRouter>

        <ModalKeluar />
      </UserContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
