import Loadable from "../../../components/Loadable";

const LembarAbsensi = Loadable(() => import("./LembarAbsensi"));
const KartuPelajar = Loadable(() => import("./KartuPelajar"));
const DaftarKuesioner = Loadable(() => import("./Kuesioner/DaftarKuesioner"));
const DetilKuesioner = Loadable(() => import("./Kuesioner/DetilKuesioner"));
const DetilKuesionerUser = Loadable(
  () => import("./Kuesioner/DetilKuesionerUser"),
);
const KuesionerBaru = Loadable(() => import("./Kuesioner/KuesionerBaru"));
const Pengumuman = Loadable(() => import("./Pengumuman"));
const DetilPengumuman = Loadable(() => import("./DetilPengumuman"));
const TambahPengumuman = Loadable(() => import("./TambahPengumuman"));

export default {
  KartuPelajar,
  LembarAbsensi,
  DaftarKuesioner,
  DetilKuesioner,
  DetilKuesionerUser,
  KuesionerBaru,
  TambahPengumuman,
  DetilPengumuman,
  Pengumuman,
};
