import React, { useContext } from "react";
import _ from "lodash";
import { Dimmer, Header, Loader } from "semantic-ui-react";
import { UserContext } from "../../context";
import { useMuridShowListKelas } from "../../api/murid";
import BaseContent from "../../layouts/BaseContent";
import { CardRaporMurid } from "./CardRaporMurid";

export const DaftarRaporMurid = () => {
  const session = useContext(UserContext);

  const nama = _.get(session, "user.nama", "");
  const nis = _.get(session, "user.nis", "");
  const nisn = _.get(session, "user.nisn", "");
  const muridId = _.get(session, "user._id", "");

  const { data, isFetching } = useMuridShowListKelas(muridId);

  const listKelas = _.chain(data)
    .get("result.list_kelas", [])
    .orderBy(["tahun", "semester"], ["desc", "desc"])
    .value();

  const loading = isFetching;

  const title = `Rapor: ${nama}`;
  const subtitle = `NIS: ${nis || "-"} / NISN: ${nisn || "-"}`;
  return (
    <BaseContent title={title}>
      <div>
        <Header as="h3">
          {title}
          <Header.Subheader>{subtitle}</Header.Subheader>
        </Header>
      </div>
      <div />
      <div>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>

        {/* <div style={{ display: "flex", flexWrap: "wrap", gap: "2em", paddingTop: ".5em" }}> */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(400px, 1fr))",
            gap: "2em",
            padding: ".2em",
          }}
        >
          {listKelas.map((kelas) => (
            <CardRaporMurid {...kelas} muridId={muridId} />
          ))}
        </div>
      </div>
    </BaseContent>
  );
};
