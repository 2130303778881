import _ from "lodash";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { Header, Divider, Image } from "semantic-ui-react";
import React, { useContext, useState } from "react";

import LoginForm from "./LoginForm";
import ForgetForm from "./ForgetForm";

import { UserContext } from "../../context";
import { LOGIN_FORM, FORGET_FORM } from "../../helpers/constant";

import logo_impuls from "../../assets/logo_impuls.png";
import logo_sekolah from "../../assets/logo_sekolah.png";

import background from "../../assets/background.jpg";
import backgroundMini from "../../assets/background_mini.jpg";

import Media from "react-media";

const Layout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    position: relative;
    flex-direction: column;
    > div:first-child {
      background-image: url(${backgroundMini});
      background-attachment: initial;
      clip-path: none;
      > section {
        padding: 2em 1.2em;
        min-height: 40vh;
      }
    }
    > div:last-child {
      padding: 4em 1.2em 0 1.2em;
    }
  }
`;

const LeftSide = styled.div`
  flex: 7;
  background: rgba(0, 0, 0, 0) url(${background}) no-repeat scroll 50% 50% /
    cover;
  clip-path: ellipse(100% 150% at 0% 50%);
  position: relative;
  display: flex;

  > section {
    width: 100%;
    padding-right: 1em;
    padding-left: 12%;
    display: flex;
    align-items: center;
    align-self: center;
  }
`;

const RightSide = styled.div`
  flex: 3;
  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  padding: 0 6em;
  position: relative;
  justify-content: center;
  flex-direction: column;
`;

const Brand = styled.div`
  display: flex;
  position: absolute;
  bottom: 2em;
  left: 2em;
  flex-direction: row;
  align-items: flex-end;
`;
const BrandResponsive = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 4em;
	padding-bottom: 1em
	align-items: flex-end;
`;

const H6 = styled(Header)`
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 0.4px;
  padding: 0;
  margin: 0;
  color: #fff;

  @media only screen and (max-width: 768px) {
    color: black;
  }
`;

const LoginIndex = () => {
  const [form, setForm] = useState(LOGIN_FORM);
  const session = useContext(UserContext);
  // console.log(session);

  const nama_sekolah = _.get(session, "sekolah.nama");
  return (
    <React.Fragment>
      {session.is_login ? (
        <Redirect to="/" />
      ) : (
        <Layout>
          <LeftSide>
            <section>
              <div>
                <Header as="h3" className="white">
                  Selamat datang di{" "}
                </Header>
                <Header as="h1" className="white">
                  Sistem Manajemen Sekolah
                </Header>
              </div>
              <Media query={{ minWidth: 768 }}>
                {(v) =>
                  v ? (
                    <Brand>
                      <div>
                        {" "}
                        <Image size="mini" src={logo_impuls} />{" "}
                      </div>
                      <div style={{ paddingLeft: "1em" }}>
                        <H6 as="h6">Copyright © {new Date().getFullYear()}</H6>
                        <H6 as="h6">PT. Impuls Digital Indonesia</H6>
                        <H6 as="h6">All right reserved</H6>
                      </div>
                    </Brand>
                  ) : (
                    <span />
                  )
                }
              </Media>
            </section>
          </LeftSide>
          <RightSide>
            <Image size="tiny" src={logo_sekolah} />
            <Header as="h2" color="theme">
              {nama_sekolah}
            </Header>
            <Divider />
            {form === LOGIN_FORM && <LoginForm setForm={setForm} />}
            {form === FORGET_FORM && <ForgetForm setForm={setForm} />}
            <Media query={{ minWidth: 768 }}>
              {(v) =>
                !v ? (
                  <BrandResponsive>
                    <div>
                      {" "}
                      <Image size="mini" src={logo_impuls} />{" "}
                    </div>
                    <div style={{ paddingLeft: "1em" }}>
                      <H6 as="h6">Copyright © {new Date().getFullYear()}</H6>
                      <H6 as="h6">PT. Impuls Digital Indonesia</H6>
                      <H6 as="h6">All right reserved</H6>
                    </div>
                  </BrandResponsive>
                ) : (
                  <span />
                )
              }
            </Media>
          </RightSide>
        </Layout>
      )}
    </React.Fragment>
  );
};

export default LoginIndex;
