import Loadable from "../components/Loadable";

import Ikhtisar from "../views/WaliMurid/Ikhtisar/Index";
import Pengumuman from "../views/Karyawan/Pengumuman/Index";
import KuesionerView from "../views/WaliMurid/KuesionerView/Index";

const Profil = Loadable(() => import("../views/WaliMurid/Profil"));

const base_url = "";
export default [
  {
    path: [base_url, "pengumuman"].join("/"),
    component: Pengumuman.DaftarPengumumanView,
  },
  {
    path: [base_url, "pengumuman", ":id"].join("/"),
    component: Pengumuman.DetilPengumumanView,
  },

  {
    path: [base_url, "ikhtisar", "nilai-rapor"].join("/"),
    component: Ikhtisar.NilaiRapor,
  },
  {
    path: [base_url, "ikhtisar", "umum"].join("/"),
    component: Ikhtisar.IkhtisarUmum,
  },
  {
    path: [base_url, "ikhtisar", "akademik"].join("/"),
    component: Ikhtisar.IkhtisarAkademik,
  },
  {
    path: [base_url, "ikhtisar", "kesiswaan"].join("/"),
    component: Ikhtisar.IkhtisarKesiswaan,
  },
  {
    path: [base_url, "ikhtisar", "tata-tertib"].join("/"),
    component: Ikhtisar.IkhtisarTataTertib,
  },
  {
    path: [base_url, "ikhtisar", "keuangan"].join("/"),
    component: Ikhtisar.IkhtisarKeuangan,
  },

  {
    path: [base_url, "kuesioner"].join("/"),
    component: KuesionerView.DaftarKuesioner,
  },
  {
    path: [base_url, "kuesioner", ":_id"].join("/"),
    component: KuesionerView.DetilKuesioner,
  },

  { path: [base_url, "profil"].join("/"), component: Profil },
];
