import React, { useContext } from "react";
import styled from "styled-components";
import { UserContext } from "../../../../context";
import logo_impuls from "../../../../assets/logo_impuls.png";

const MoleculeLogoImpuls = () => {
  const { state } = useContext(UserContext);
  return (
    <Style nav={state.navigation.active ? true : state.navigation.hover}>
      <img src={logo_impuls} alt="" />
      <p>
        Copyright © <br />
        PT. Impuls Digital Indonesia
        <br />
        All Right Reserved
      </p>
    </Style>
  );
};

const Style = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.nav ? "40px auto" : "40px")};
  gap: 16px;
  align-self: end;
  align-items: center;
  padding: 10px 16px;

  img {
    width: 40px;
    place-self: center;
  }

  p {
    font-size: 0.8rem;
    line-height: 1.5em;
    display: ${(props) => (props.nav ? "default" : "none")};
  }

  @media (max-width: 480px) {
    grid-template-columns: 38px auto;
    display: default;

    p {
      display: block;
    }
  }
`;

export default MoleculeLogoImpuls;
