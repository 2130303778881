import React, { useContext } from "react";
import { UserContext } from "../../context";
import { Button, Header } from "semantic-ui-react";
import { isEmpty } from "lodash";
import Media from "react-media";

const Pembayaran = () => {
  const { model, user } = useContext(UserContext);

  const IS_PPDB = model === "ppdb";
  if (!IS_PPDB) return "";

  if (user?.paid === false && !isEmpty(user.bill_link)) {
    return (
      <div
        style={{
          maxHeight: "50px",
          height: "50px",
          display: "flex",
          background: "#fe9a06",
          justifyContent: "center",
          padding: ".6em 0",
          alignItems: "center",
        }}
      >
        <div>
          <Media query={{ maxWidth: 768 }}>
            {(v) =>
              v ? (
                ""
              ) : (
                <Header size="small" color="white">
                  Mohon lakukan pembayaran biaya Penerimaan Murid Baru (PPDB)
                </Header>
              )
            }
          </Media>
        </div>
        <div style={{ padding: "0 1em" }}>
          <Button
            color="green"
            compact
            onClick={() => {
              window.open(`https://${user.bill_link}`, "_blank");
            }}
          >
            Pembayaran biaya PPDB
          </Button>
        </div>
      </div>
    );
  }

  return "";
};

export default Pembayaran;
