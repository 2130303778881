import React, { useContext } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { logout } from "../../../../fetcher";
import { UserContext } from "../../../../context";
import { cloneDeep, get, set } from "lodash";
import { useForm } from "react-hook-form";

export const ModalKeluar = () => {
  const session = useContext(UserContext);

  const { handleSubmit, formState } = useForm();

  const open = get(session, "ui.modal_keluar", false);

  const setOpen = (status) => {
    const newSession = { ...cloneDeep(session) };
    set(newSession, "ui.modal_keluar", status);
    return session.setSession(newSession);
  };

  const onLogout = handleSubmit(async () => {
    await logout();
    await session.checkUser();
  });

  const isLoading = formState.isSubmitting;

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      size="mini"
    >
      <Modal.Header>Keluar/ Log Out</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            Apakah Anda yakin akan <b>Keluar/Log Out</b> dari Sistem?
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          size="small"
          onClick={() => setOpen(false)}
          disabled={isLoading}
        >
          <Icon name="times" />
          Batal
        </Button>
        <Button
          onClick={onLogout}
          negative
          disabled={isLoading}
          loading={isLoading}
        >
          <Icon name="sign out" />
          Keluar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
