import _ from "lodash";
import React from "react";
import Media from "react-media";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Divider, Dimmer, Segment, Loader } from "semantic-ui-react";

import Error403 from "../collection/Error/403";
import Error404 from "../collection/Error/404";
import Error401 from "../collection/Error/401";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: 768px) {
    display: grid;
    position: relative;
    grid-template-rows: 1fr auto;
    min-width: 100vw;

    .section-content {
      padding: 1rem;
      overflow: auto;
    }
    .section-action {
      background: #f4f6f8;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0.8rem;
      button {
        margin-left: 1rem;
      }
    }
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FullLoading = () => (
  <Segment style={{ height: "95%" }}>
    <Dimmer active={true}>
      <Loader />
    </Dimmer>
  </Segment>
);

const Head = styled.div`
  display: flex;
  flex-direction: row;

  > div:first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  > div:last-child {
    display: flex;

    > div > button {
      margin: 0 0 0 1rem;
    }
  }
`;
const Content = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;

  > div {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 1.5rem;
  }
`;

const DEFAULT_TITLE = "Sistem Manajemen Sekolah";
const BaseContent = (props) => {
  let title = _.get(props, "title", "");
  const status = _.chain(props).get("status").toNumber().value();
  const children = _.get(props, "children", []);
  const TitleContent = _.get(children, 0, <div />);
  const ActionContent = _.get(children, 1, <div />);
  const MainContent = _.get(children, 2, <div />);

  if (status === 404) {
    title = "Eror 404";
  }
  if (status === 403) {
    title = "Eror 403";
  }

  if (!_.chain(document).get("title").isEmpty().value()) {
    document.title = title || DEFAULT_TITLE;
  }
  if (status === 0) {
    document.title = DEFAULT_TITLE;
  }
  return (
    <Layout>
      {status === 0 && <FullLoading />}
      {status === 403 && <Error403 />}
      {status === 404 && <Error404 />}
      {status === 401 && <Error401 />}
      {(_.isNaN(status) || status === 200) && (
        <Media query={{ maxWidth: 768 }}>
          {(v) =>
            v ? (
              <>
                <div className="section-content">
                  <div>{TitleContent}</div>
                  <div>
                    <Divider />
                  </div>
                  <div>{MainContent}</div>
                </div>
                {!_.isEmpty(ActionContent.props) && (
                  <div className="section-action">{ActionContent}</div>
                )}
              </>
            ) : (
              <>
                <Head>
                  <div>{TitleContent}</div>
                  <div>{ActionContent}</div>
                </Head>
                <div>
                  <Divider />
                </div>
                <Content>{MainContent}</Content>
              </>
            )
          }
        </Media>
      )}
    </Layout>
  );
};

BaseContent.propTypes = {
  title: PropTypes.string,
  status: PropTypes.number,
};

export default BaseContent;
