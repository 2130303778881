import Loadable from "../../../components/Loadable";

const TinggalKelas = Loadable(() => import("./TinggalKelas/TinggalKelas"));
const DaftarMapel = Loadable(() => import("./DaftarMapel/DaftarMapel"));
const DetilDaftarMapel = Loadable(
  () => import("./DaftarMapel/DetilDaftarMapel"),
);
const ParameterSikap = Loadable(
  () => import("./ParameterSikap/ParameterSikap"),
);
const BebanMapel = Loadable(() => import("./BebanMapel/BebanMapel"));

export default {
  DaftarMapel,
  DetilDaftarMapel,
  TinggalKelas,
  BebanMapel,
  ParameterSikap,
};
