import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { murid } from "../fetcher";
import { isEmpty } from "lodash";
import { saveFile } from "../helpers/utils";

const resource = "murid";

const muridKey = {
  show: (id, arg) => [resource, "show", id, JSON.stringify(arg)],
  showListKelas: (id) => [resource, "show-list-kelas", id],
  showHakAkses: (id, arg) => [
    resource,
    "show-hak-akses",
    id,
    JSON.stringify(arg),
  ],
  showIkhtisarUmum: (id) => [resource, "show-ikhtisar-umum", id],

  printRapor: (id, arg) => [resource, "print-rapor", id, JSON.stringify(arg)],

  indexKunci: (arg) => [resource, "index-kunci", JSON.stringify(arg)],
};

export const useMuridIndexKunci = (query) => {
  return useQuery({
    enabled: !isEmpty(query),
    queryKey: muridKey.indexKunci(query),
    queryFn: async () => {
      const resp = await murid.indexKunci(query);
      return resp.result || {};
    },
  });
};

export const useMuridIndexNIS = (listNIS) => {
  const str = listNIS.join(",");
  return useQuery({
    enabled: !isEmpty(listNIS),
    queryKey: muridKey.indexKunci(str),
    queryFn: async () => {
      const resp = await murid.indexNIS({ list: str });
      return resp.result || {};
    },
  });
};

export const useMuridShow = (id, arg) => {
  return useQuery({
    queryKey: muridKey.show(id, arg),
    queryFn: () => murid.show(id, arg),
  });
};

export const useMuridShowListKelas = (id) => {
  return useQuery({
    enabled: !!id,
    queryKey: muridKey.showListKelas(id),
    queryFn: () => murid.listKelas(id),
  });
};

export const useMuridShowIkhtisarUmum = (id) => {
  return useQuery({
    enabled: !!id,
    queryKey: muridKey.showIkhtisarUmum(id),
    queryFn: async () => {
      const resp = await murid.ikhtisarUmum(id);
      if (resp.status_code >= 200 && resp.status_code < 300) {
        return resp;
      }

      throw new Error(resp.status_code);
    },
  });
};

export const useMuridShowHakAkses = (id, query) => {
  return useQuery({
    enabled: !!id,
    queryKey: muridKey.showHakAkses(id, query),
    queryFn: async () => {
      const resp = await murid.showHakAkses(id, query);
      if (resp.status_code >= 200 && resp.status_code < 300) {
        return resp;
      }

      throw new Error(resp.status_code);
    },
  });
};

export const useMuridPrintRapor = (id) => {
  return useMutation({
    mutationFn: async (query) => {
      const resp = await murid.printRapor(id, query);
      return saveFile(resp, query.filename);
    },
  });
};

// export const useMuridShowMany = (ids, arg) => {
//   return useQueries({
//     queries: ids.map(id => ({
//       queryKey: muridKey.show(id, arg),
//       queryFn: () => murid.show(id, arg)
//     }))
//   })
// }

/**
 * -------------------------------------
 * MURID MUTATION
 * -------------------------------------
 * */
export const useMuridIndexActive = () => {
  return useMutation({
    mutationFn: async (arg) => {
      const resp = await murid.indexActive(arg);
      return resp;
    },
  });
};

export const useMuridStoreKunci = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (arg) => {
      const resp = await murid.storeKunci(arg);
      return resp;
    },
    onSuccess: () => {
      client.invalidateQueries([resource, "index-kunci"]);
    },
  });
};
