const reducer = (state, action) => {
  switch (action.type) {
    case "HANDLE_NAV":
      return {
        ...state,
        navigation: {
          ...state.navigation,
          active: !state.navigation.active,
        },
      };
    case "HOVER_NAV":
      return {
        ...state,
        navigation: {
          ...state.navigation,
          hover: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
