import Media from "react-media";
import React, { useContext } from "react";
import { Header } from "semantic-ui-react";
import styled from "styled-components";
import { UserContext } from "../../../../context";
import logo_sekolah from "../../../../assets/logo_sekolah.png";

const MoleculeSekolah = () => {
  const { state, sekolah } = useContext(UserContext);
  return (
    <Media query={{ maxWidth: 768 }}>
      {(v) =>
        v ? null : (
          <Style nav={state.navigation.active ? true : state.navigation.hover}>
            <div className="image">
              <img src={logo_sekolah} alt="" />
            </div>
            <div className="dashboard-header">
              <Header color="theme">{sekolah.nama}</Header>
            </div>
          </Style>
        )
      }
    </Media>
  );
};

const Style = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.nav ? "37px auto" : "1fr")};
  gap: 16px;
  padding: 16px;
  align-items: center;
  height: 54px;

  div > img {
    width: 2.8em;
  }

  .dashboard-header {
    display: ${(props) => (props.nav ? "block" : "none")};

    .ui.header {
      font-size: 16px;
    }

    p {
      display: none;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: ${(props) =>
      props.nav ? "37px auto" : "37px auto"};
    gap: 10px;
    padding: 0;

    .dashboard-header {
      display: block;

      p {
        font-size: 12px;
        display: block;
      }
    }
  }
`;

export default MoleculeSekolah;
