import React from "react";
import queryString from "query-string";

import Loadable from "../../components/Loadable";

export const TPPDBLogin = Loadable(
  () => import("../../templates/PPDBPeserta/Index"),
);

const PPDBPendaftaran = ({ location }) => {
  const { token, p } = queryString.parse(location.search);
  return <TPPDBLogin token={token} page={p} />;
};

export default PPDBPendaftaran;
