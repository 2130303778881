import _ from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import { Form, Icon, Message } from "semantic-ui-react";

import { forget } from "../../fetcher";

import { LOGIN_FORM } from "../../helpers/constant";

const BtnContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const ForgetForm = ({ setForm }) => {
  const [isLoading, toggleLoading] = useState(false);
  const [formValue, setFormValue] = useState({});
  const [formStatus, setFormStatus] = useState({});

  const handleChange = (e, data) => {
    const { name, value } = data;
    setFormStatus({});
    return setFormValue(_.assign({}, formValue, { [name]: value }));
  };
  const handleSubmit = async () => {
    toggleLoading(true);
    const { email } = formValue;
    const response = await forget({ email });
    if (response.status_code === 200) {
      setFormStatus({ status: "success" });
    } else {
      const formStatus = { status: "error", props: {} };
      switch (response.message) {
        case "User: Document not found": {
          formStatus.props = {
            error: true,
            size: "tiny",
            header: "Email tidak ditemukan",
            content: (
              <p>
                Untuk mengetahui surel terdaftar, silakan hubungi Tim Kami di{" "}
                <a href="https://wa.me/6281228800048">081228800048</a> via
                WhatsApp atau SMS
              </p>
            ),
          };
          break;
        }
        default:
          formStatus.props = {
            error: true,
            size: "tiny",
            header: "Terjadi kesalahan",
          };
      }
      toggleLoading(false);
      return setFormStatus(formStatus);
    }
    return toggleLoading(false);
  };

  const { email = "" } = formValue;
  const is_error = _.isEqual(formStatus.status, "error");
  return (
    <div>
      {_.isEqual(formStatus.status, "success") ? (
        <div>
          <p>Surel sudah terkirim. Cek Surel kamu!</p>
          <Form>
            <Form.Button primary basic onClick={() => setForm(LOGIN_FORM)}>
              <Icon name="arrow left" />
              Coba Login Lagi
            </Form.Button>
          </Form>
        </div>
      ) : (
        <div>
          <p>
            Masukan alamat surel Anda dan kami mengirimkan pranala bantuan untuk
            menyetel ulang password Anda.
          </p>
          <Form className="theme" error={is_error}>
            <Form.Field required>
              <label className="theme">Alamat Surel/Email</label>
              <Form.Input
                disabled={isLoading}
                name="email"
                onChange={handleChange}
                value={email}
                placeholder="Masukan alamat surel Anda"
              />
              {!_.isEmpty(formStatus) && <Message {...formStatus.props} />}
              <BtnContainer>
                <Form.Button
                  color="theme"
                  loading={isLoading}
                  type="submit"
                  onClick={handleSubmit}
                  disabled={email.length < 5 || isLoading}
                >
                  Kirim
                </Form.Button>
                <Form.Button
                  color="theme"
                  basic
                  onClick={() => setForm(LOGIN_FORM)}
                >
                  <Icon name="arrow left" />
                  Coba Login Lagi
                </Form.Button>
              </BtnContainer>
            </Form.Field>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ForgetForm;
